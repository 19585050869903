import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Img from 'next/legacy/image';

import SideMenu from '@tmap/mmm-style-guide/src/SideMenu';
import { useWidth } from '@tmap/mmm-style-guide/src/useWidth';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import {
  Assessment, Business, Group, ExpandMore, LocationOn, LocalOffer, FileCopy, MenuBook, HomeIcon,
} from '@tmap/mmm-style-guide/src/Icon';
import { ListItemIcon } from '@tmap/mmm-style-guide/src/List';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import fullLogoDark from '@tmap/mmm-style-guide/src/common/mmm-full-logo-darkmode.png';
import { Box } from '@tmap/mmm-style-guide/src/Box';

import Header from '../components/header';
import Footer from '../components/footer';
import ProfileMenu from '../components/profileMenu';
import ProfileAvatar from '../components/profileAvatar';

import useCurrentUser from '../hooks/useCurrentUser';
import useCurrentOrg from '../hooks/useCurrentOrg';
import useOrgs from '../hooks/useOrgs';

import { DARK_THEME } from '../lib/themes';
import toOrgUrl from '../lib/toOrgUrl';
import getDisplayName from '../lib/userProfile/getDisplayName';

const insetOptions = {
  undefined: { default: '0px', sm: '0px' },
  large: { default: '100px', sm: '24px' },
  small: { default: '48px', sm: '24px' },
};

const backgroundOptions = (theme) => ({
  undefined: theme.palette.common.white,
  dim: theme.palette.grey[50],
  dimmer: theme.palette.grey[100],
});

const Flex = styled('div')(() => ({
  display: 'flex',
}));
const FullWidth = styled('div')(() => ({
  width: '100%',
  minHeight: '100vh',
}));

const BodyWrapper = styled('div', {
  shouldForwardProp: (p) => !['headerOffset', 'inset', 'background'].includes(p),
})(({
  theme, headerOffset, inset, background,
}) => ({
  background: backgroundOptions(theme)[background],
  height: '100%',
  paddingTop: headerOffset ? '100px' : '0px',
  paddingLeft: insetOptions[inset].default,
  paddingRight: insetOptions[inset].default,
  [theme.breakpoints.down('md')]: {
    paddingLeft: insetOptions[inset].sm,
    paddingRight: insetOptions[inset].sm,
  },
}));

function Logo() {
  return (
    <Link href='/'>
      <Img src={fullLogoDark} alt='Make My Move' width={2001 / 8} height={262 / 8} />
    </Link>
  );
}

function FullWrapper(props) {
  const {
    headerOffset = false,
    inset,
    breadcrumbs,
    background,
    AppbarChild,
  } = props;
  const drawerWidth = 240;
  const smallDrawerWidth = 80;
  const width = useWidth();
  const auth = useAuth0();
  const user = useCurrentUser();
  const org = useCurrentOrg();
  const orgs = useOrgs();
  const router = useRouter();

  const [open, drawerToggle] = useState(width === null ? null : width !== 'xs');
  if (open === null && width !== null) drawerToggle(width !== 'xs');

  const appropriateDrawerWidth = open || width === 'xs' ? drawerWidth : smallDrawerWidth;

  const queryParams = '(?:[?].*)?';
  const subrouteOrQueryParams = '(?:[/?].*)?';
  const orgRegexStr = '[/]org[/][a-zA-Z0-9_-]{8}';
  const isDashboard = new RegExp(`${orgRegexStr}[/]?${queryParams}$`).test(router.asPath);
  const isValueDashboard = new RegExp(`${orgRegexStr}[/]value-dashboard${subrouteOrQueryParams}$`).test(router.asPath);
  const isMovers = new RegExp(`${orgRegexStr}(?:[/]movers${subrouteOrQueryParams})$`).test(router.asPath);
  const isInfo = new RegExp(`${orgRegexStr}[/]info${subrouteOrQueryParams}$`).test(router.asPath);
  const isCommunities = new RegExp(`${orgRegexStr}[/]communities${subrouteOrQueryParams}$`).test(router.asPath);
  const isOffers = new RegExp(`${orgRegexStr}[/]offers${subrouteOrQueryParams}$`).test(router.asPath);
  const isPlaybook = new RegExp(`${orgRegexStr}[/]playbooks${subrouteOrQueryParams}$`).test(router.asPath);
  const isTemplates = new RegExp(`${orgRegexStr}[/]message-templates${subrouteOrQueryParams}$`).test(router.asPath);

  if (open === null) return null;
  return (
    <Flex>
      <div style={{ zIndex: 1 }}>
        <ThemeProvider theme={DARK_THEME}>
          <SideMenu
            drawerWidth={appropriateDrawerWidth}
            drawerToggle={drawerToggle}
            open={open}
            Logo={Logo}
          >
            {org
              && (
                <SideMenu.ListTop>
                  <SideMenu.ResponsiveItem href={org.toUrl('')} Icon={HomeIcon} text='Home' isOn={isDashboard} />
                  <SideMenu.ResponsiveItem href={org.toUrl('value-dashboard')} Icon={Assessment} text='Value Dashboard' isOn={isValueDashboard} />
                  <SideMenu.ResponsiveItem href={org.toUrl('movers')} Icon={Group} text='Movers' isOn={isMovers} />
                  <SideMenu.SectionTitle>MANAGE</SideMenu.SectionTitle>
                  <SideMenu.ResponsiveItem href={org.toUrl('info')} Icon={Business} text='My Organization' isOn={isInfo}>
                    {orgs.length > 1 && orgs.map((o) => (
                      <SideMenu.ResponsiveItem key={o._id} href={toOrgUrl(o, 'info')} text={o.name || o.shortId} level={1} bold={o._id === org._id} />
                    ))}
                  </SideMenu.ResponsiveItem>
                  <SideMenu.ResponsiveItem href={org.toUrl('communities')} Icon={LocationOn} text='My Communities' isOn={isCommunities} />
                  <SideMenu.ResponsiveItem href={org.toUrl('offers')} Icon={LocalOffer} text='My Offers' isOn={isOffers} />
                  <SideMenu.ResponsiveItem href={org.toUrl('message-templates')} Icon={FileCopy} text='Templates' isOn={isTemplates} />
                  <SideMenu.ResponsiveItem href={org.toUrl('playbooks')} Icon={MenuBook} text='Playbooks' isOn={isPlaybook} />
                </SideMenu.ListTop>
              )}
            {auth.isAuthenticated
              && (
                <SideMenu.ListBottom>
                  {width !== 'xs'
                    && (
                      <ProfileMenu>
                        <SideMenu.Item isOn drawerWidth={appropriateDrawerWidth}>
                          <ListItemIcon sx={{ marginTop: '2px', marginBottom: '2px' }}>
                            <ProfileAvatar size={28} />
                          </ListItemIcon>
                          {open
                            && (
                              <>
                                <SideMenu.ItemText>
                                  {user ? getDisplayName(user) : auth.user?.email}
                                </SideMenu.ItemText>
                                <ExpandMore />
                              </>
                            )}

                        </SideMenu.Item>
                      </ProfileMenu>
                    )}
                </SideMenu.ListBottom>
              )}
          </SideMenu>
        </ThemeProvider>
      </div>
      <Header
        drawerWidth={width === 'xs' ? 0 : appropriateDrawerWidth}
        color={props.headerColor || 'transparent'}
        drawerToggle={drawerToggle}
        breadcrumbs={breadcrumbs}
        AppbarChild={AppbarChild}
      />
      <FullWidth>
        {AppbarChild
          && (
            <Box sx={{
              display: { xs: 'flex', md: 'none' }, marginTop: '76px', marginBottom: '-86px', width: '100%', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <AppbarChild />
            </Box>
          )}
        <BodyWrapper headerOffset={headerOffset} inset={inset} background={background}>
          {props.children}
        </BodyWrapper>
        <Footer />
      </FullWidth>
    </Flex>
  );
}

export default FullWrapper;

import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import nestGet from '@tmap/mmm-core/nestGet';
import toOrgUrl from '../lib/toOrgUrl';

const useCurrentOrg = () => {
  const router = useRouter();
  const { orgId } = router.query;
  const org = useSelector((state) => nestGet(state, ['orgStore', 'orgs', orgId]), shallowEqual);
  const isLoading = useSelector((state) => state?.orgStore?.isLoading);
  const memoedOrg = useMemo(() => ({
    ...org,
    isLoading,
    toUrl: (path) => toOrgUrl(org, path),
  }), [org, isLoading]);
  if (!orgId || !org) return undefined;
  return memoedOrg;
};

export default useCurrentOrg;

import React from 'react';

import { Avatar } from '@tmap/mmm-style-guide/src/Avatar';
import { Person } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import imageUrlBuilder from '@sanity/image-url';

import useCurrentUser from '../hooks/useCurrentUser';
import client from '../client';

const builder = imageUrlBuilder(client);

const StyledPicture = styled('picture')(() => ({
  width: '100%',
}));
const StyledImg = styled('img')(() => ({
  width: '100%',
}));

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: (p) => !['size', 'centered', 'borderColor', 'borderThickness', 'loaded'].includes(p),
})(({
  theme,
  size,
  centered,
  borderColor,
  borderThickness,
  loaded,
}) => ({
  width: `${size}px`,
  height: `${size}px`,
  position: 'relative',
  zIndex: 0,
  ...(centered ? {
    margin: 'auto',
  } : {}),
  ...(loaded ? {
    background: 'transparent',
  } : {}),
  // https://stackoverflow.com/a/51496341
  ...(borderColor ? {
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: -1,
      inset: 0,
      background: typeof borderColor === 'function'
        ? borderColor((theme.vars || theme).palette)
        : `linear-gradient(45deg, ${theme.palette.primary.main} 10%, ${theme.palette.aqua.light} 90%)`,
      padding: borderThickness,
      borderRadius: '50%',
      mask: 'linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0)',
      maskComposite: 'exclude',
      WebkitMaskComposite: 'xor',
    },
  } : {}),
}));

function ProfileAvatar(props) {
  const {
    size,
    centered,
    user,
    borderColor,
    borderThickness = '4%',
  } = props;

  const currentUser = useCurrentUser();

  const computedUser = user || currentUser;
  const loaded = computedUser && computedUser.profileImage;

  return (
    <StyledAvatar
      centered={centered}
      size={size}
      borderColor={borderColor}
      borderThickness={borderThickness}
      loaded={loaded}
    >
      {loaded ? (
        <StyledPicture>
          <source srcSet={builder.image(computedUser.profileImage).format('webp').width(size).height(size).url()} />
          <StyledImg src={builder.image(computedUser.profileImage).width(size).height(size).url()} />
        </StyledPicture>
      ) : (
        <Person sx={{ fontSize: `${size * 0.6}px` }} />
      )}
    </StyledAvatar>
  );
}

export default ProfileAvatar;

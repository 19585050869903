import React, { useRef, useEffect } from 'react';
import { AppBar } from '@tmap/mmm-style-guide/src/AppBar';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Hidden } from '@tmap/mmm-style-guide/src/Hidden';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { MenuIcon, ArrowBack } from '@tmap/mmm-style-guide/src/Icon';
import { Toolbar } from '@tmap/mmm-style-guide/src/Toolbar';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Tooltip } from '@tmap/mmm-style-guide/src/Tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import styledBy from '@tmap/mmm-style-guide/src/util/styledBy';
import ProfileAvatar from './profileAvatar';
import ProfileMenu from './profileMenu';
import { useRouter } from 'next/router';
import { Skeleton } from '@tmap/mmm-style-guide/src/Skeleton';
import { Box } from '@tmap/mmm-style-guide/src/Box';

const forColor = (theme) => styledBy('color', {
  transparent: theme.palette.common.white,
  default: theme.palette.primary.veryDark,
  undefined: theme.palette.common.white
});

const backColor = (theme) => styledBy('color', {
  transparent: theme.palette.primary.veryDark,
  default: theme.palette.common.white,
  undefined: theme.palette.common.white
})

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  color: forColor(theme),
  backgroundColor: backColor(theme),
  marginBottom: theme.spacing(1)
}))
const ShiftableAppBar = styled(StyledAppBar, {
  shouldForwardProp: (p) => !['drawerWidth', 'prevDrawerWidth'].includes(p),
})(({ theme, drawerWidth, prevDrawerWidth }) => ({
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: prevDrawerWidth < drawerWidth ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
  }),
  boxShadow: '0px 1px 8px 0px #0000001F',
  background: theme.palette.common.white,
}))

const ToolbarContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(-1),
  },
  marginRight: theme.spacing(1),
  width: '100%'
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: forColor(theme)
}))

const StyledProfileAvatar = styled(ProfileAvatar)(({ theme }) => ({
  color: backColor(theme),
  backgroundColor: forColor(theme)
}))

const Header = (props) => {
  const {
    color = 'default',
    drawerToggle,
    drawerWidth,
    breadcrumbs = [],
    AppbarChild
  } = props;
  const user = useAuth0();
  const router = useRouter();

  const handleBack = () => {
    router.back()
  }

  const prevDrawerRef = useRef(drawerWidth);
  const prevDrawerWidth = prevDrawerRef.current;
  useEffect(() => {
    prevDrawerRef.current = drawerWidth;
  }, [drawerWidth])

  return (
    <ShiftableAppBar position='fixed' color={color} elevation={color === 'transparent' ? 0 : 3} prevDrawerWidth={prevDrawerWidth} drawerWidth={drawerWidth}>
      <Toolbar>
        <ToolbarContent>
          <Grid container direction='row' justifyContent='center' alignItems='center' spacing={1}>
            <Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
              <StyledIconButton onClick={() => drawerToggle(true)}><MenuIcon /></StyledIconButton>
            </Grid>
            <Grid item sx={{ marginRight: 2, display: { xs: 'none', sm: 'block' } }}>
              {breadcrumbs.length > 1 &&
                <Tooltip title='Back' aria-label='back'>
                  <IconButton onClick={handleBack}>
                    <ArrowBack />
                  </IconButton>
                </Tooltip>
              }
            </Grid>
            <Grid item sx={{ maxWidth: 'calc(100% - 100px)' }}>
              {breadcrumbs.map((crumb, i) => crumb ?
                i === breadcrumbs.length - 1 ?
                  <Typography component='span' fontWeight={breadcrumbs.length === 1 ? 600 : 400} key={'breadcrumb-' + i}>
                    {crumb}
                  </Typography>
                  :
                  <Typography component='span' color='textSecondary' key={'breadcrumb-' + i}>
                    {crumb}&ensp;/&ensp;
                  </Typography>
                :
                <Skeleton sx={{ display: 'inline-block' }} width='12ch' key={'breadcrumb-' + i} />
              )}
            </Grid>
            <Grid item xs></Grid>
            {user.isAuthenticated &&
              <Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
                <ProfileMenu>
                  <StyledProfileAvatar size={32} />
                </ProfileMenu>
              </Grid>
            }
            {AppbarChild &&
              <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
                <AppbarChild />
              </Grid>
            }
          </Grid>
        </ToolbarContent>
      </Toolbar>
    </ShiftableAppBar>
  )
};

export default Header;